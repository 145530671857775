<form #form="ngForm">
  <mat-form-field appearance="fill">
    <mat-label>Practice Name</mat-label>
    <input matInput type="text" name="name" [(ngModel)]="data.Name" required />
    <mat-error>Practice Name is required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>NPI #</mat-label>
    <input
      matInput
      appInputLimiter
      [maxLength]="constants.validation.npi.max"
      [pool]="constants.validation.pools.numeric"
      type="text"
      name="npi"
      [(ngModel)]="data.npi"
      required
    />
    <mat-error>NPI is required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Phone #</mat-label>
    <input
      matInput
      type="text"
      name="telephone"
      [(ngModel)]="data.telephone"
      appInputLimiter
      [maxLength]="constants.validation.telephoneNumber.max"
      [pool]="constants.validation.pools.numeric"
      required
    />
    <mat-error>Phone is required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Address</mat-label>
    <textarea matInput name="address" [(ngModel)]="data.address" required></textarea>
    <mat-error>Address is required</mat-error>
  </mat-form-field>
  <div align="end">
    <button mat-raised-button (click)="cancel()" [disabled]="form.submitted" color="warn" type="button">Cancel</button>
    &nbsp;
    <button mat-raised-button (click)="submit()" [disabled]="form.invalid || !form.dirty || form.submitted" type="submit">Save</button>
  </div>
</form>
