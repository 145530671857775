export type Uid = string;
export type FirebaseAuthToken = string;
export type OktaAccessToken = string;
export type Npi = string;
export type Email = string;
export type Password = string;
export type EmailVerificationCode = string;
export type Name = string;
export type Telephone = string;
export type FactorId = string;
export type Claims = {
  hcpPracticeAdmin?: boolean;
};

export type Invitation = Claims & {
  userUid?: Uid;
  email: Email;
  medicalPracticeID: Uid;
  status?: 'UNREGISTERED' | 'REGISTERED' | 'EMAIL_VERIFIED' | 'COMPLETE';
};

export type MedicalPractice = {
  address: string;
  Name: string;
  npi: Npi;
  telephone: Telephone;
};

export type Device = {
  FollowingPhysicianName: string;
  HospitalName: string;
  ImplantDate: string;
  PatientName: string;
  Model: string;
  SerialNum: string;
  DateOfBirth: string;
  MinLifeRemaining: string; //number: decimal
  ExpLifeRemaining: string; //number: decimal
  MaxLifeRemaining: string; //number: decimal
  UNID: string; //index
};

export type Devices = Device[];

export type PersonName = {
  first: Name;
  last: Name;
};

export type FirestoreProfile = {
  npi?: string;
  medicalPracticeID: Uid;
};

export type Profile = FirestoreProfile & {
  name: PersonName;
  telephone: Telephone;
  email: Email;
};
