import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { RegExpDefinitions } from 'shared';

@Directive({
  selector: '[appEmail]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailDirective, multi: true }],
})
export class EmailDirective implements Validator {
  validate(control: AbstractControl): EmailValidationErrors | null {
    const input = control.value as string;
    if (!input) {
      return {
        required: true,
        invalid: true,
      } as EmailValidationErrors;
    }
    const email = input.trim();
    if (email.length == 0) {
      return {
        required: true,
        invalid: true,
      } as EmailValidationErrors;
    }

    const test = RegExpDefinitions.email.test(email);
    if (test) {
      return null;
    } else {
      return { invalid: true, required: false } as EmailValidationErrors;
    }
  }
}

export function EmailValidator(): ValidatorFn {
  const emailDirective = new EmailDirective();
  return emailDirective.validate;
}

export type EmailValidationErrors = ValidationErrors & {
  required: boolean;
  invalid: boolean;
};
