<div fxLayout="row wrap" fxLayoutAlign="space-around center">
  <div fxFlex fxLayoutAlign="start center" style="margin: 25px">
    <h1 *ngIf="patientDeviceInfoViewModel$ | async as patientDeviceInfoViewModel">{{ patientDeviceInfoViewModel?.PatientName }}</h1>
  </div>
</div>
<div fxLayout="row wrap" fxLayoutAlign="center stretch">
  <div fxFlex style="margin: 10px">
    <mat-card>
      <mat-card-subtitle>Patient & Device Information</mat-card-subtitle>
      <mat-card-content *ngIf="patientDeviceInfoViewModel$ | async as patientDeviceInfoViewModel">
        <table>
          <caption></caption>
          <tr>
            <td>Patient Initials</td>
            <td *ngIf="patientDeviceInfoViewModel?.PatientInitials; else elsePatientInitialsNotAvailableBlock">
              {{ patientDeviceInfoViewModel?.PatientInitials }}
            </td>
            <ng-template #elsePatientInitialsNotAvailableBlock>Not available</ng-template>
          </tr>
          <tr>
            <td>Date of Birth</td>
            <td>{{ patientDeviceInfoViewModel?.DateOfBirth }}</td>
          </tr>
          <tr>
            <td>Date of Implant</td>
            <td>{{ patientDeviceInfoViewModel?.ImplantDate }}</td>
          </tr>
          <tr>
            <td>Generator Model #</td>
            <td>{{ patientDeviceInfoViewModel?.Model }}</td>
          </tr>
          <tr>
            <td>Generator Serial #</td>
            <td>{{ patientDeviceInfoViewModel?.Serial }}</td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxFlex style="margin: 10px">
    <mat-card>
      <mat-card-subtitle>Battery life calculations</mat-card-subtitle>
      <mat-card-content *ngIf="patientBatteryInfoViewModel$ | async as patientBatteryInfoViewModel">
        <p>
          <span style="font-weight: bold">{{ patientBatteryInfoViewModel?.YearsToNEOS }}</span> years remaining to NEOS
        </p>
        <p>
          As of <span style="font-weight: bold">{{ patientBatteryInfoViewModel?.AsOfDate }}</span>
        </p>
        <p>
          This calculation is based on parameter/diagnostic programming history available within the database for dates spanning from
          <span style="font-weight: bold">{{ patientBatteryInfoViewModel?.FirstInterrogatedDate }}</span> to
          <span style="font-weight: bold">{{ patientBatteryInfoViewModel?.LastInterrogatedDate }}</span
          >.
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="div">
  <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
    <caption></caption>

    <ng-container matColumnDef="InterrogatedDate">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">{{ element.InterrogatedDate }}</td>
    </ng-container>

    <ng-container matColumnDef="OutputCurrent">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Output mA</th>
      <td mat-cell *matCellDef="let element">{{ element.OutputCurrent }}</td>
    </ng-container>

    <ng-container matColumnDef="Frequency">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Frequency Hz</th>
      <td mat-cell *matCellDef="let element">{{ element.Frequency }}</td>
    </ng-container>

    <ng-container matColumnDef="PulseWidth">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Pulse Width</th>
      <td mat-cell *matCellDef="let element">{{ element.PulseWidth }}</td>
    </ng-container>

    <ng-container matColumnDef="OnTime">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>On Time</th>
      <td mat-cell *matCellDef="let element">{{ element.OnTime }}</td>
    </ng-container>

    <ng-container matColumnDef="OffTime">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Off Time</th>
      <td mat-cell *matCellDef="let element">{{ element.OffTime }}</td>
    </ng-container>

    <ng-container matColumnDef="LeadImpedanceValue">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Lead Impedance Value</th>
      <td mat-cell *matCellDef="let element">{{ element.LeadImpedanceValue }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td *ngIf="phdDataAvailabilityMessage$ | async as message" class="mat-cell" colspan="4">{{ message }}</td>
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[15, 30, 50, 100]" showFirstLastButtons aria-label="Select page of periodic elements"> </mat-paginator>
</div>
