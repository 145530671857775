import { LOGIN_CALLBACK_ROUTE } from 'src/app/app-routing.module';

export const environment = {
  production: true,

  firebaseConfig: {
    apiKey: 'AIzaSyA1Tp4-gsAKzXaWyS5DxS-X1s_Lkkmk6gI',
    authDomain: 'beta-502b7.firebaseapp.com',
    projectId: 'beta-502b7',
    storageBucket: 'beta-502b7.appspot.com',
    messagingSenderId: '570077878101',
    appId: '1:570077878101:web:521f20db70fa86393841dc',
    measurementId: 'G-MRELZ8FETG',
  },
  okta_domain: 'mylivanova.oktapreview.com',
  okta_clientId: '0oa17h9k45j5cP9110h8',
  redirectUri: `https://beta.mylivanova.com/${LOGIN_CALLBACK_ROUTE}`,
};
