import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { RegExpDefinitions } from 'shared';

@Directive({
  selector: '[appPassword]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordDirective, multi: true }],
})
export class PasswordDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const input = control.value as string;
    if (!input) {
      return {
        invalid: true,
        required: true,
      } as PasswordValidationErrors;
    }
    const password = input.trim();
    if (password.length == 0) {
      return {
        invalid: true,
        required: true,
      } as PasswordValidationErrors;
    }

    const test = RegExpDefinitions.password.test(password);
    if (test) {
      return null;
    } else {
      const error: PasswordValidationErrors = { invalid: true };
      if (password.length < 6) {
        error.tooShort = true;
      }
      if (password.length > 64) {
        error.tooLong = true;
      }
      return error;
    }
  }
}

export function PasswordValidator(): ValidatorFn {
  const passwordDirective = new PasswordDirective();
  return passwordDirective.validate;
}

export declare type PasswordValidationErrors = ValidationErrors & {
  invalid?: boolean;
  tooLong?: boolean;
  tooShort?: boolean;
  required?: boolean;
};
