import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { constants, Profile, RegExpDefinitions } from 'shared';
import { ProfileService } from 'src/app/common/services/profile.service';

@Component({
  selector: 'app-edit-profile-dialog',
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss'],
})
export class EditProfileDialogComponent {
  RegExpDefinitions = RegExpDefinitions;
  constants = constants;
  constructor(
    public dialogRef: MatDialogRef<EditProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Profile,
    public profileService: ProfileService
  ) {}

  async submit() {
    await this.profileService.update(this.data);
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
