import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PatientHistoryDataViewModel } from './patient-history-data-view-model';
import { PatientHistoryDataService } from './patient-history-data.service';

@Component({
  selector: 'app-patient-history-data',
  templateUrl: './patient-history-data.component.html',
  styleUrls: ['./patient-history-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientHistoryDataComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['InterrogatedDate', 'OutputCurrent', 'Frequency', 'PulseWidth', 'OnTime', 'OffTime', 'LeadImpedanceValue'];
  dataSource = new MatTableDataSource<PatientHistoryDataViewModel>();

  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private phdDataAvailabilitySubject = new BehaviorSubject('Loading patient history data ...');
  phdDataAvailabilityMessage$ = this.phdDataAvailabilitySubject.asObservable();

  patientHistoryDataSubscription!: Subscription;

  patientDeviceInfoViewModel$ = this.patientHistoryDataService.patientDeviceInfo$.pipe(
    catchError((err) => {
      console.log(err);
      return EMPTY;
    })
  );

  patientBatteryInfoViewModel$ = this.patientHistoryDataService.patientBatterInfo$.pipe(
    catchError((err) => {
      console.log(err);
      return EMPTY;
    })
  );

  constructor(private patientHistoryDataService: PatientHistoryDataService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const patientID: string | null = this.route.snapshot.paramMap.get('id');
    if (patientID) {
      this.patientHistoryDataService.selectedPatientChanged(patientID);
    }

    this.patientHistoryDataSubscription = this.patientHistoryDataService.patientHistoryData$.subscribe({
      next: (patientHistoryData) => {
        this.dataSource.data = patientHistoryData;
        if (this.dataSource.data.length > 0) this.phdDataAvailabilitySubject.next('');
      },
      error: (err) => {
        console.log(err);
        this.phdDataAvailabilitySubject.next('No patient history data available.');
      },
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.patientHistoryDataSubscription.unsubscribe();
  }
}
