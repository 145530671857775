import { CdkStepper } from '@angular/cdk/stepper';
import { Component } from '@angular/core';

@Component({
  selector: 'livn-stepper',
  templateUrl: './livn-stepper.component.html',
  styleUrls: ['./livn-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: LivnStepperComponent }],
})
export class LivnStepperComponent extends CdkStepper {
  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}
