import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  GetProfileRequest,
  GetProfileResponse,
  GET_PROFILE,
  Profile,
  UpdateProfileRequest,
  UpdateProfileResponse,
  UPDATE_PROFILE,
} from 'shared';
import { IProfileService } from './profile.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfileService implements IProfileService {
  profile: BehaviorSubject<Profile | undefined> = new BehaviorSubject<Profile | undefined>(undefined);
  constructor(private functions: AngularFireFunctions) {}
  async update(data: Partial<Profile>): Promise<void> {
    await this.functions.httpsCallable<UpdateProfileRequest, UpdateProfileResponse>(UPDATE_PROFILE)(data).pipe(take(1)).toPromise();
    await this.fetch();
  }
  async fetch(): Promise<void> {
    const profile = await this.functions.httpsCallable<GetProfileRequest, GetProfileResponse>(GET_PROFILE)().pipe(take(1)).toPromise();
    this.profile.next(profile);
  }
}
