<form #form="ngForm">
  <mat-form-field appearance="fill">
    <mat-label>First Name</mat-label>
    <input matInput type="text" name="firstName" [(ngModel)]="data.name.first" required />
    <mat-error>First name is required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Last Name</mat-label>
    <input matInput type="text" name="lastName" [(ngModel)]="data.name.last" required />
    <mat-error>Last name is required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>NPI #</mat-label>
    <input
      matInput
      appInputLimiter
      [maxLength]="constants.validation.npi.max"
      [pool]="constants.validation.pools.numeric"
      [pattern]="RegExpDefinitions.npi"
      type="text"
      name="npi"
      [(ngModel)]="data.npi"
    />
    <mat-error>Invalid NPI</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Phone #</mat-label>
    <input
      matInput
      type="text"
      name="telephone"
      [(ngModel)]="data.telephone"
      appInputLimiter
      [pattern]="RegExpDefinitions.telephoneNumber"
      [maxLength]="constants.validation.telephoneNumber.max"
      [pool]="constants.validation.pools.numeric"
    />
    <mat-error>Invalid Phone Number</mat-error>
  </mat-form-field>
  <div align="end">
    <button mat-raised-button (click)="cancel()" [disabled]="form.submitted" color="warn" type="button">Cancel</button>
    &nbsp;
    <button mat-raised-button (click)="submit()" [disabled]="form.invalid || !form.dirty || form.submitted" type="submit">Save</button>
  </div>
</form>
