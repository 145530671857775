<div class="wrapper">
  <h1>My Profile</h1>
  <div fxLayout="row" fxLayoutGap="20px">
    <div class="inner">
      <mat-card>
        <div class="mat-card-strip purple"></div>
        <mat-card-title>Practice Information</mat-card-title>
        <mat-card-content>
          <table>
            <caption>
              Practice Information
            </caption>
            <tr class="header-row">
              <th>Field</th>
              <th>value</th>
            </tr>
            <tr>
              <td>Practice Name</td>
              <td>{{ (medicalPractice | async)?.Name }}</td>
            </tr>
            <tr>
              <td>NPI #</td>
              <td>{{ (medicalPractice | async)?.npi }}</td>
            </tr>
            <tr>
              <td>Phone #</td>
              <td>{{ (medicalPractice | async)?.telephone || '' | mask: '(000) 000-0000' }}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{{ (medicalPractice | async)?.address }}</td>
            </tr>
          </table>
        </mat-card-content>
        <button (click)="editPractice()" mat-mini-fab aria-label="Edit provider information">
          <mat-icon svgIcon="app-edit"></mat-icon>
        </button>
      </mat-card>
    </div>
    <div class="inner">
      <mat-card>
        <div class="mat-card-strip blue"></div>
        <mat-card-title>Provider Information</mat-card-title>
        <mat-card-content>
          <table>
            <caption>
              Provider Information
            </caption>
            <tr class="header-row">
              <th>Field</th>
              <th>value</th>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{ (profile | async)?.name?.first }} {{ (profile | async)?.name?.last }}</td>
            </tr>
            <tr>
              <td>NPI #</td>
              <td>{{ (profile | async)?.npi }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>
                {{ (profile | async)?.email }}
              </td>
            </tr>
            <tr>
              <td>Phone #</td>
              <td>{{ (profile | async)?.telephone || '' | mask: '(000) 000-0000' }}</td>
            </tr>
          </table>
        </mat-card-content>
        <button (click)="editProfile()" mat-mini-fab aria-label="Edit provider information">
          <mat-icon svgIcon="app-edit"></mat-icon>
        </button>
      </mat-card>
    </div>
  </div>
</div>
