import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import { MedicalPracticesService } from '../common/services/medical-practices.service';
import { InvitationsService } from './invitations.service';
import { Invitation } from './invitations.service.interface';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss'],
})
export class InvitationComponent implements OnInit, OnDestroy {
  invitation = new BehaviorSubject<Invitation | undefined>(undefined);
  invitationSubscription!: Subscription;
  medicalPracticeName: Observable<string>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private invitationsService: InvitationsService,
    private medicalPracticeService: MedicalPracticesService
  ) {
    this.medicalPracticeName = this.invitation.pipe(
      switchMap((invitation) => {
        if (invitation) {
          return this.medicalPracticeService.medicalPracticeById(invitation.medicalPracticeID);
        } else {
          return of(undefined);
        }
      }),
      map((medicalPractice) => medicalPractice?.Name || 'loading...')
    );
  }

  ngOnInit(): void {
    this.invitationSubscription = this.activatedRoute.params
      .pipe(
        pluck('id'),
        switchMap((id: string) => {
          return this.invitationsService.getInvitation(id);
        })
      )
      .subscribe((i) => {
        this.invitation.next(i);
      });
  }

  ngOnDestroy(): void {
    this.invitationSubscription.unsubscribe();
  }
}
