import { FirebaseAuthToken, OktaAccessToken } from '.';
import { Email, FactorId, FirestoreProfile, Npi, Password, PersonName, Telephone, Uid } from './data.model';

export type RequestEmailVerificationCodeRequest = { invitationUid: Uid; email: Email };
export type RequestEmailVerificationCodeResponse = {
  factorId: FactorId;
};

export type SubmitEmailVerificationCodeResponse = void;
export type SubmitEmailVerificationCodeRequest = { email: Email; invitationUid: Uid; userUid: Uid; code: string; factorId: FactorId };

export type RegisterUserRequest = { invitationUid: Uid; email: Email; password: Password };
export type RegisterUserResponse = void;

export type GetProfileRequest = void;
export type GetProfileResponse = {
  medicalPracticeID: Uid;
  npi?: string;
  name: PersonName;
  email: Email;
  telephone: Telephone;
};
export type SetProfileRequest = {
  userUid: Uid;
  invitationUid: Uid;
  npi?: string;
  name: PersonName;
  email: Email;
  telephone: string;
};
export type SetProfileResponse = void;

export type UpdateProfileRequest = {
  npi?: string;
  name?: PersonName;
  telephone?: Telephone;
};
export type UpdateProfileResponse = void;

export type UpdateFirestoreProfileRequest = { uid: Uid; npi?: Npi };
export type UpdateFirestoreProfileResponse = void;
export type SetFirestoreProfileRequest = { uid: Uid; npi?: Npi; medicalPracticeID: Uid };
export type SetFirestoreProfileResponse = void;
export type GetFirestoreProfileRequest = { uid: Uid };
export type GetFirestoreProfileResponse = FirestoreProfile;

export type UserExistsRequest = {
  invitationUid: Uid;
  email: Email;
};
export type UserExistsResponse = boolean;

export type ExchangeTokenRequest = {
  token: OktaAccessToken;
};
export type ExchangeTokenResponse = FirebaseAuthToken;
