import { of, OperatorFunction } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

export const toBoolean: OperatorFunction<any, boolean> = map((input: any) => {
  return !!input;
});

export const toFalse: OperatorFunction<any, false> = map((input: any) => {
  return false;
});

export const catchErrorToFalse: OperatorFunction<boolean, boolean> = catchError((error) => {
  return of(false);
});

export function stripUndefined<T>(): OperatorFunction<T | undefined, T> {
  return filter((input: T) => input !== undefined) as OperatorFunction<T | undefined, T>;
}
export function stripFirstUndefined<T>(first: boolean): OperatorFunction<T | undefined, T> {
  return filter((input: T) => {
    if (input !== undefined && first) {
      first = false;
      return false;
    }
    return true;
  }) as OperatorFunction<T | undefined, T>;
}

export function toUndefined(): OperatorFunction<any, undefined> {
  return map((_) => {
    return undefined;
  });
}
export function toVoid(): OperatorFunction<any, undefined> {
  return map((_) => {
    return void 0;
  });
}
