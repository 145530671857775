import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MedicalPractice, Profile } from 'shared';
import { AuthenticationService } from '../common/services/authentication.service';
import { MedicalPracticesService } from '../common/services/medical-practices.service';
import { ProfileService } from '../common/services/profile.service';
import { EditPracticeDialogComponent } from './edit-practice-dialog/edit-practice-dialog.component';
import { EditProfileDialogComponent } from './edit-profile-dialog/edit-profile-dialog.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  profile: Observable<Profile | undefined> = this.profileService.profile;
  medicalPractice: Observable<MedicalPractice | undefined> = this.medicalPracticeService.medicalPractice;

  constructor(
    public authenticationService: AuthenticationService,
    public profileService: ProfileService,
    public medicalPracticeService: MedicalPracticesService,
    public dialog: MatDialog
  ) {
    this.profileService.fetch();
  }

  async editProfile() {
    let profile = await this.profile.pipe(take(1)).toPromise();
    this.dialog.open(EditProfileDialogComponent, {
      width: '400px',
      data: { ...profile, name: { ...profile!.name } },
    });
  }

  async editPractice() {
    let practice = await this.medicalPractice.pipe(take(1)).toPromise();
    this.dialog.open(EditPracticeDialogComponent, {
      width: '400px',
      data: { ...practice },
    });
  }
}
