import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { PATIENTS_COLLECTION, PATIENTS_HISTORY_DATA_COLLECTION, PATIENT_BATTERY_INFO_COLLECTION } from 'shared';
import { PatientBatteryInfoViewModel } from './patient-battery-info-view-model';
import { PatientDeviceInfoViewModel } from './patient-device-info-view-model';
import { PatientHistoryDataViewModel } from './patient-history-data-view-model';

@Injectable({
  providedIn: 'root',
})
export class PatientHistoryDataService {
  constructor(private firestore: AngularFirestore) {}

  private patientSelectedSubject = new BehaviorSubject<string>('');
  private patientSelectedAction$ = this.patientSelectedSubject.asObservable();

  patientDeviceInfo$ = this.patientSelectedAction$.pipe(
    filter((patientID) => Boolean(patientID)),
    switchMap((patientID) =>
      this.firestore.doc<PatientDeviceInfoViewModel>(`${PATIENTS_COLLECTION}/${patientID}`).valueChanges({ idField: 'PatientID' })
    ),
    catchError((err) => throwError(err))
  );

  patientBatterInfo$ = this.patientSelectedAction$.pipe(
    filter((patientID) => Boolean(patientID)),
    switchMap((patientID) =>
      this.firestore
        .doc<PatientBatteryInfoViewModel>(`${PATIENT_BATTERY_INFO_COLLECTION}/${patientID}`)
        .valueChanges({ idField: 'PatientID' })
    ),
    catchError((err) => throwError(err))
  );

  patientHistoryData$ = this.patientDeviceInfo$.pipe(
    filter((patientDeviceInfo) => Boolean(patientDeviceInfo?.MedicalPracticeID) && Boolean(patientDeviceInfo?.PatientID)),
    switchMap((patientDeviceInfo) =>
      this.firestore
        .collection<PatientHistoryDataViewModel>(PATIENTS_HISTORY_DATA_COLLECTION, (ref) =>
          ref
            .where('PatientID', '==', patientDeviceInfo?.PatientID)
            .where('MedicalPracticeID', '==', patientDeviceInfo?.MedicalPracticeID)
            .orderBy('InterrogatedDate', 'desc')
        )
        .valueChanges()
    ),
    catchError((err) => throwError(err))
  );

  selectedPatientChanged(patientID: string): void {
    this.patientSelectedSubject.next(patientID);
  }
}
