import { Injectable } from '@angular/core';
import { Action, AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { trace } from '@angular/fire/performance';
import { map } from 'rxjs/operators';
import { Invitation, INVITATIONS_COLLECTION, Uid } from 'shared';
import { IInvitationsService } from 'src/app/invitations/invitations.service.interface';
import { stripUndefined } from '../common/utils/operators.rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvitationsService implements IInvitationsService {
  private invitations = this.firestore.collection<Invitation>(INVITATIONS_COLLECTION);

  constructor(private firestore: AngularFirestore) {}

  getInvitation(uid: string) {
    return this.invitations
      .doc(uid)
      .valueChanges({ idField: 'uid' })
      .pipe(trace(`InvitationService.getInvitation(${uid})`), stripUndefined<Invitation & { uid: Uid }>());
  }

  invitationExists(uid: string) {
    return this.invitations
      .doc(uid)
      .snapshotChanges()
      .pipe(
        trace(`InvitationService.invitationExists(${uid})`),
        map((documentSnapshot: Action<DocumentSnapshot<Invitation>>) => {
          return documentSnapshot.payload.exists;
        })
      );
  }
}
