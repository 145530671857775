import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../common/services/authentication.service';

@Component({
  selector: 'app-portal',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  hcpPracticeAdmin: boolean = false;
  subscription?: Subscription;
  constructor(public authenticationService: AuthenticationService) {}
  ngOnInit(): void {
    this.subscription = this.authenticationService.user.subscribe((user) => {
      this.hcpPracticeAdmin = user?.claims?.hcpPracticeAdmin || false;
    });
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async logout() {
    await this.authenticationService.logout();
  }
}
