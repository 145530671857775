<div fxLayout="row wrap" fxLayoutAlign="space-around center">
  <div fxFlex fxLayoutAlign="start center" style="margin: 25px">
    <h1>Patients</h1>
  </div>
  <div fxFlex style="margin: 25px">
    <mat-form-field appearance="standard">
      <input matInput (keyup)="applyFilter($event)" placeholder="Search any field" #input />
    </mat-form-field>
  </div>
</div>

<div class="div">
  <mat-card>
    <mat-card-content>
      <p>
        Years to NEOS calculation is only considered an estimate. Any gaps in available data were interpolated based on the LivaNova battery
        life calculation methodology. Other factors (including magnet activity, additional parameter changes not available to LivaNova,
        etc.) were not accounted for in this calculation. LivaNova recommends continued monitoring of the device’s function and battery life
        for most accurate results through appropriate diagnostic tests.
      </p>
    </mat-card-content>
  </mat-card>
</div>
<div class="div">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <caption></caption>
    <ng-container matColumnDef="PatientName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient Name</th>
      <td mat-cell *matCellDef="let row" (click)="selectPatient(row.PatientID)">
        {{ row.PatientName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="DateOfBirth">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient DOB</th>
      <td mat-cell *matCellDef="let row" (click)="selectPatient(row.PatientID)">
        {{ row.DateOfBirth }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Model">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Device Model</th>
      <td mat-cell *matCellDef="let row" (click)="selectPatient(row.PatientID)">
        {{ row.Model }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Serial">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Device Serial Number</th>
      <td mat-cell *matCellDef="let row" (click)="selectPatient(row.PatientID)">
        {{ row.Serial }}
      </td>
    </ng-container>
    <ng-container matColumnDef="LastInterrogatedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Reading Date</th>
      <td mat-cell *matCellDef="let row" (click)="selectPatient(row.PatientID)">
        {{ row.LastInterrogatedDate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="YearsToNEOS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Years to NEOS</th>
      <td mat-cell *matCellDef="let row" (click)="selectPatient(row.PatientID)">
        {{ row.YearsToNEOS }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td *ngIf="dataSource.data.length > 0 && input.value.length > 0" class="mat-cell" colspan="4">No data matching the search "{{ input.value }}"</td>
      <td *ngIf="dataAvailabilityMessage$ | async as message" class="mat-cell" colspan="4">{{message}}</td>
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[15, 30, 50, 100]" showFirstLastButtons aria-label="Select page of patients"> </mat-paginator>
</div>
