<div fxLayout="row wrap" fxLayoutAlign="space-around center">
  <div fxFlex fxLayoutAlign="start center" style="margin: 25px">
    <h1>Resources</h1>
  </div>

  <table class="center">
    <caption></caption>
    <tr>
      <td>
        <a target="_blank" href="../../assets/CME-Medscape-Flyer.pdf" rel="noopener">
          <mat-card>
            <mat-card-subtitle>CME opportunities </mat-card-subtitle>
          </mat-card>
        </a>
      </td>
    </tr>
    <tr>
      <td>
        <a target="_blank" href=" https://vnstherapy.com/healthcare-professionals/resources/webinars" rel="noopener">
          <mat-card>
            <mat-card-subtitle>livanova.com webinars</mat-card-subtitle>
          </mat-card>
        </a>
      </td>
    </tr>
    <tr>
      <td>
        <a target="_blank" href="https://www.livanova.com/epilepsy-vnstherapy/en-us/hcp/mri-safety" rel="noopener">
          <mat-card>
            <mat-card-subtitle>MRI Safety & Scan Guidelines</mat-card-subtitle>
          </mat-card>
        </a>
      </td>
    </tr>
    <tr>
      <td>
        <a
          target="_blank"
          href="https://www.livanova.com/epilepsy-vnstherapy/getmedia/79ebd11f-9884-4d4d-8251-fcabbcec64b6/vns-therapy_patient-eligibility_education_authorization_2018.pdf"
          rel="noopener"
        >
          <mat-card>
            <mat-card-subtitle>Start form</mat-card-subtitle>
          </mat-card>
        </a>
      </td>
    </tr>
    <tr>
      <td>
        <a target="_blank" href="https://vnstherapy.com/healthcare-professionals/resources/physician-manuals" rel="noopener">
          <mat-card>
            <mat-card-subtitle> Physician Manuals </mat-card-subtitle>
          </mat-card>
        </a>
      </td>
    </tr>
    <tr>
      <td>
        <a
          target="_blank"
          href="https://www.livanova.com/epilepsy-vnstherapy/getmedia/1fd43151-7004-4110-aed0-49d3c380e79b/im-7600955-epi-vns-therapy-2022-code-sheets-full-version.pdf"
          rel="noopener"
        >
          <mat-card>
            <mat-card-subtitle> Reimbursement </mat-card-subtitle>
          </mat-card>
        </a>
      </td>
    </tr>
    <tr>
      <td>
        <a target="_blank" href="https://www.livanova.com/epilepsy-vnstherapy/en-us/hcp/product-training" rel="noopener">
          <mat-card>
            <mat-card-subtitle> Product Training </mat-card-subtitle>
          </mat-card>
        </a>
      </td>
    </tr>
  </table>
</div>
