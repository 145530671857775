import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PatientListService } from './patient-list.service';
import { PatientViewModel } from './patient-view-model';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['PatientName', 'DateOfBirth', 'Model', 'Serial', 'LastInterrogatedDate', 'YearsToNEOS'];
  dataSource = new MatTableDataSource<PatientViewModel>();
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private dataAvailabilitySubject = new BehaviorSubject<string>('loading patients data....');
  dataAvailabilityMessage$ = this.dataAvailabilitySubject.asObservable();

  patientListSubscription!: Subscription;

  constructor(private patientListService: PatientListService, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.getPatients();
  }

  ngOnDestroy(): void {
    this.patientListSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getPatients(): void {
    this.patientListSubscription = this.patientListService.patients$.subscribe({
      next: (patients) => {
        this.dataSource.data = patients;
        if (this.dataSource.data.length > 0) {
          this.dataAvailabilitySubject.next('');
        }
      },
      error: (err) => {
        console.log(err);
        this.dataAvailabilitySubject.next('No patient data available.');
      },
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectPatient(patientID: string) {
    this.router.navigate(['..', 'patients', patientID], { relativeTo: this.activatedRoute });
  }
}
