import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { throwError } from 'rxjs';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';
import { PATIENTS_COLLECTION, PROFILES_COLLECTION } from 'shared';
import { AuthenticationService } from '../common/services/authentication.service';
import { MedicalPracticeViewModel } from './medical-practice-view-model';
import { PatientViewModel } from './patient-view-model';

@Injectable({
  providedIn: 'root',
})
export class PatientListService {
  constructor(private firestore: AngularFirestore, private authentictionService: AuthenticationService) {}

  loggedInUser$ = this.authentictionService.user;

  medicalPractice$ = this.loggedInUser$.pipe(
    switchMap((user) => {
      if (!user) {
        return throwError(() => 'logged in user is null');
      }
      console.log(`user id: ${user.uid}`); // explicitly logging user id for beta for quick issue identification and resolution
      return this.firestore.doc<MedicalPracticeViewModel>(`${PROFILES_COLLECTION}/${user?.uid}`).valueChanges();
    }),
    catchError((err) => throwError(err))
  );

  patients$ = this.medicalPractice$.pipe(
    switchMap((medicalPractice) => {
      if (!medicalPractice?.medicalPracticeID) {
        return throwError(() => 'no medical practice for logged in user.');
      }

      return this.firestore
        .collection<PatientViewModel>(PATIENTS_COLLECTION, (ref) =>
          ref.where('MedicalPracticeID', '==', medicalPractice?.medicalPracticeID).orderBy('PatientName')
        )
        .valueChanges({ idField: 'PatientID' });
    }),
    shareReplay(1),
    catchError((err) => throwError(err))
  );
}
