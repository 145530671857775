import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MedicalPractice, MEDICAL_PRACTICES_COLLECTION, Uid } from 'shared';
import { IMedicalPracticesService } from './medical-practices.service.interface';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class MedicalPracticesService implements IMedicalPracticesService {
  constructor(public fireStore: AngularFirestore, public profileService: ProfileService) {}

  medicalPracticeById(uid: Uid): Observable<MedicalPractice | undefined> {
    return this.fireStore.collection<MedicalPractice>(MEDICAL_PRACTICES_COLLECTION).doc(uid).valueChanges();
  }

  get medicalPractice(): Observable<MedicalPractice | undefined> {
    return this.profileService.profile.pipe(
      switchMap((profile) => {
        if (profile) {
          return this.fireStore
            .collection<MedicalPractice>(MEDICAL_PRACTICES_COLLECTION)
            .doc(profile.medicalPracticeID)
            .valueChanges({ idField: 'uid' });
        } else {
          return of(undefined);
        }
      })
    );
  }
  async setMedicalPractice(data: MedicalPractice) {
    const profile = this.profileService.profile.getValue();
    await this.fireStore
      .collection<MedicalPractice>(MEDICAL_PRACTICES_COLLECTION)
      .doc(profile!.medicalPracticeID)
      .set(data, { merge: true });
  }
}
