import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { constants, MedicalPractice } from 'shared';
import { MedicalPracticesService } from 'src/app/common/services/medical-practices.service';

@Component({
  selector: 'app-edit-practice-dialog',
  templateUrl: './edit-practice-dialog.component.html',
  styleUrls: ['./edit-practice-dialog.component.scss'],
})
export class EditPracticeDialogComponent {
  constants = constants;
  constructor(
    public dialogRef: MatDialogRef<EditPracticeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MedicalPractice,
    public medicalPracticeService: MedicalPracticesService
  ) {}

  async submit() {
    await this.medicalPracticeService.setMedicalPractice(this.data);
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
